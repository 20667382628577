import { ReactNode, useEffect, useState } from 'react';

// NoSSR component for Nextjs
export default function NoSSR({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return <>{children}</>;
}
