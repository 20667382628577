import Image from 'next/image';
import Link from 'next-translate-routes-multi-domain/link';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { getProducts } from '@/lib/repository/products/products';

import Layout from '@/components/layout/Layout';
import Seo from '@/components/Seo';
import SimpleSlider from '@/components/section/CarouselList';
import { ProductListItem } from '@/components/section/ProductsList';
import { GetStaticProps } from 'next';
import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next-translate-routes-multi-domain';
import { isLocaleGerman } from '@/lib/data-transformer/gindumacUtils';
import { QueryClient, dehydrate, useQuery } from '@tanstack/react-query';
import { BsChevronCompactDown } from 'react-icons/bs';
import useIsMobile from '@/lib/hooks/useIsMobile';
import { pushDataLayer } from '@/lib/utilities/analytics/gtm';
import GoogleReviews from '@/components/visualization/GoogleReviews';

export default function HomePage() {
  const { t } = useTranslation();
  // products from react-query
  const { locale } = useRouter();
  const { data } = useQuery(['products'], () =>
    getProducts({ page_size: 10, locale: locale ?? 'en-US' })
  );
  const products = data?.data?.products;
  return (
    <Layout>
      <Seo />
      <main className='max-w-full'>
        <Hero />
        <section className='mx-auto flex max-w-[95vw] flex-col justify-center space-y-4 bg-white pb-12 pt-12 md:pb-32'>
          <div
            className='mx-auto animate-pulse cursor-pointer'
            onClick={() => {
              document
                .getElementById('new-entries')
                ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            <BsChevronCompactDown className='h-12 w-12' />
          </div>
          <h2 className='mb-4 text-center text-2xl font-extralight leading-6 text-brand-500'>
            {t('products:NEW_ENTRIES')}
          </h2>
          <div className='mx-auto max-w-full xl:max-w-7xl' id='new-entries'>
            {products && (
              <SimpleSlider>
                {products.map((product) => (
                  <ProductListItem key={product.id} p={product} />
                ))}
              </SimpleSlider>
            )}
          </div>
          <div className='mx-4 md:mx-auto'>
            <Link href='/products'>
              <button
                className='mt-4 bg-brand-500 px-32 py-6 text-center font-secondary uppercase text-secondary-500 hover:bg-brand-800'
                onClick={() =>
                  pushDataLayer('buttonClick', {
                    label: 'See all machines Home Page',
                  })
                }
              >
                {t('products:SEE_ALL')}
              </button>
            </Link>
          </div>
        </section>
        <Benefits />
        <GoogleReviews />
        <WhatTheySay />
        <KnownFromLogos />
      </main>
    </Layout>
  );
}

function Hero() {
  const { t } = useTranslation();
  return (
    <div className='inner-shaddow-bottom relative h-[40em] w-full md:mb-56'>
      <div className='bg-gradient-to-b absolute z-10 h-full w-full from-transparent to-black' />
      <Image
        src='/images/home/bg-hero.png'
        alt='Logo'
        layout='fill'
        objectFit='cover'
        objectPosition='center'
        className='z-0'
        priority={true}
      />
      <div className='absolute bottom-0 left-0 right-0 top-0 z-20 flex flex-col items-center justify-center px-2 pt-0 text-white md:bottom-auto md:top-auto md:pt-36'>
        <h1 className='pb-6 text-center font-normal uppercase text-white'>
          {t('products:WELCOME_MESSAGE_1')}
        </h1>
        <div className='flex w-full flex-col sm:flex-row sm:justify-center sm:space-x-4'>
          <Link href='/selling-process'>
            <a
              className='mt-4 bg-secondary-500 px-32 py-6 text-center font-secondary uppercase text-brand-500 hover:bg-secondary-800'
              onClick={() =>
                pushDataLayer('buttonClick', {
                  label: 'I want to sell',
                })
              }
            >
              {t('products:SELL_BUTTON')}
            </a>
          </Link>
          <Link href='/products'>
            <a
              className='mt-4 bg-brand-500 px-32 py-6 text-center font-secondary uppercase text-secondary-800 hover:bg-brand-800'
              onClick={() =>
                pushDataLayer('buttonClick', {
                  label: 'I want to buy',
                })
              }
            >
              {t('products:BUY_BUTTON')}
            </a>
          </Link>
        </div>
      </div>
      <div className='pointer-events-none absolute bottom-[-8em] z-20 w-full'>
        <div className='relative mx-auto hidden h-[23em] w-full lg:block'>
          <Image
            src='/images/home/hero-products.png'
            alt='Logo'
            layout='fill'
            objectFit='contain'
            objectPosition='center'
            className='z-0'
            priority={true}
          />
        </div>
      </div>
    </div>
  );
}

function Benefits() {
  const { t } = useTranslation('products');
  const [tabSelected, setTabSelected] = useState<0 | 1>(0);
  const isMobile = useIsMobile();
  function TabSelector() {
    return (
      <>
        <ul className='flex justify-around p-6'>
          <li
            className='flex w-full cursor-pointer items-center justify-center '
            onClick={() => setTabSelected(0)}
          >
            <a
              className={classNames(
                'w-full  p-3 text-center font-secondary text-xl uppercase text-brand-500',
                {
                  'border-b-2 border-b-brand-500': tabSelected === 0,
                }
              )}
              dangerouslySetInnerHTML={{
                __html: isMobile
                  ? t('products:SELLER_BENEFITS_MOBILE')
                  : t('products:SELLER_BENEFITS'),
              }}
            />
          </li>
          <li
            className='flex w-full cursor-pointer items-center justify-center '
            onClick={() => setTabSelected(1)}
          >
            <a
              className={classNames(
                'w-full p-3 text-center font-secondary text-xl uppercase text-brand-500',
                {
                  'border-b-2 border-b-brand-500': tabSelected === 1,
                }
              )}
              dangerouslySetInnerHTML={{
                __html: isMobile
                  ? t('products:BUYER_BENEFITS_MOBILE')
                  : t('products:BUYER_BENEFITS'),
              }}
            />
          </li>
        </ul>
      </>
    );
  }
  const content = [
    [
      {
        src: 'icons-01',
        text: t('USPS_TABS_QUOTE1'),
      },
      {
        src: 'icons-29',
        text: t('USPS_TABS_QUOTE2'),
      },
      {
        src: 'icons-06',
        text: t('USPS_TABS_QUOTE3'),
      },
    ],

    [
      {
        src: 'icons-12',
        text: t('USPS_TABS_QUOTE4'),
      },
      {
        src: 'icons-03',
        text: t('USPS_TABS_QUOTE5'),
      },
      {
        src: 'icons-22',
        text: t('USPS_TABS_QUOTE6'),
      },
    ],
  ];
  return (
    <div className='w-full bg-[#f5f5f5]'>
      <div className='mx-auto mb-12 max-w-7xl  pb-8'>
        <div>
          <TabSelector />
          <div id='tab-content1' className='row tabContent'>
            <ul className='grid justify-around space-y-6 md:grid-cols-3 md:space-y-0'>
              {content[tabSelected].map((usp, index) => {
                return (
                  <li className='flex flex-col items-center' key={index}>
                    <svg
                      viewBox='0 0 16 16'
                      className={`icons icon-${usp.src} mb-2 scale-125`}
                    >
                      <use xlinkHref={`#icon-${usp.src}`} />
                    </svg>
                    <h3 className='max-w-xs text-center font-extralight'>
                      {usp.text}
                    </h3>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function WhatTheySay() {
  const { t } = useTranslation('products');
  const { locale } = useRouter();
  let reviews = [
    {
      quote: t('SELLING_COMPANY_QUOTE1_TEXT'),
      company: t('SELLING_COMPANY_QUOTE1_NAME'),
    },
    {
      quote: t('SELLING_COMPANY_QUOTE2_TEXT'),
      company: t('SELLING_COMPANY_QUOTE2_NAME'),
    },
    {
      quote: t('SELLING_COMPANY_QUOTE3_TEXT'),
      company: t('SELLING_COMPANY_QUOTE3_NAME'),
    },
  ];
  if (isLocaleGerman(locale)) {
    reviews = reviews.concat([
      {
        quote:
          'Wir sind mit der problemlosen Abwicklung zufrieden. Die verkaufte Maschine entspricht in Zustand und Austattung unseren Vorstellungen. Nochmals vielen Dank',
        company: 'Jens Petzold, Geschäftsführer, präzimat Feinmechanik GmbH',
      },
      {
        quote: `Wir waren auf der Suche nach einem kompetenten Partner zum Verkauf unserer CNC-Maschine.

        Mit Gindumac haben wir ein junges engagiertes Team gefunden, welches die Abwicklung für uns mit kurzen Dienstwegen und unkompliziert durchgeführt hat.

        Für Folgeprojekte wird Gindumac hier unser erster Ansprechpartner.`,
        company: 'Buchmeier Blechbearbeitung GmbH',
      },
      {
        quote: `Dank der guten Betreuung durch Herr Gubisch, hat der Kauf und der Transport der Maschine aus England gut geklappt! Danke für die Zusammenarbeit! Ich werde mich in Zukunft auch wieder an Sie wenden, falls wir wieder eine gebrauchte Maschine kaufen!`,
        company: `Josef Ganglmayr / IMT-C GmbH / Österreich`,
      },
      {
        quote: `Schnelle Reaktionszeit und professioneller Umgang. Gerne wieder!`,
        company: `Lukas Beckmann, CNC Zerspanung GmbH & Co. KG`,
      },
    ]);
  }
  return (
    <div className='bg-[#f5f5f5]'>
      <Slider
        title={t('SELLING_COMPANY_TITLE')}
        slides={reviews.map((review, index) => {
          return (
            <div
              key={index}
              className='mx-auto flex  flex-col items-center justify-center'
              style={{
                paddingLeft: '1em',
                paddingRight: '1em',
              }}
            >
              <p className='mx-auto mt-4 max-w-lg text-center text-brand-500'>
                {review.quote}
              </p>
              <p className='mr-4 mt-4 text-right text-brand-500 opacity-50'>
                - {review.company}
              </p>
            </div>
          );
        })}
      />
    </div>
  );
}

function KnownFromLogos() {
  const { t } = useTranslation('products');
  const reviews = [
    {
      image: 'ap-grey_2.png',
      link: 'https://www.ambienteplastico.com/rent-it-nuevo-modelo-de-negocio-con-maquinaria-usada/',
    },
    {
      image: 'bl-grey_2.png',
      link: 'https://www.blechonline.de/krauss-maffei-beteiligt-sich-an-gindumac',
    },
    {
      image: 'bn-grey_2.png',
      link: 'https://www.blechnet.com/industrie-40-pioniersystem-vernetzt-gebrauchtmaschinen-a-688591/',
    },
    {
      image: 'cp-grey_2.png',
      link: 'https://www.canplastics.com/plastics-processes/kraussmaffei-acquires-stake-in-online-used-machinery-startup-gindumac/1003448415/',
    },
    {
      image: 'cnc-t-grey_2.png',
      link: 'https://www.cnctimes.com/editorial/mr-benedikt-ruf-md-gindumac-gmbh-and-mr-pankaj-patel-country-manager-gindumac-gmbh-share-their-views-about-amtex-2018-and-the-indian-market',
    },
    {
      image: 'cw-grey_2.png',
      link: 'https://www.compositesworld.com/news/kraussmaffei-invests-in-second-hand-machinery-start-up',
    },
    {
      image: 'eppm-grey_2.png',
      link: 'https://www.eppm.com/industry-news/kraussmaffei-uses-gindumac-for-new-business-model-rent-it/',
    },
    {
      image: 'fw-grey_2.png',
      link: 'https://www.form-werkzeug.de/news/uebersicht/artikel/marktspiegel-werkzeugbau-7485224.html',
    },
    {
      image: 'gr-grey_2.png',
      link: 'https://www.gruenderszene.de/automotive-mobility/gindumac-maschinen-an-und-verkauf',
    },
    {
      image: 'ibw-grey_2.png',
      link: 'https://www.ideenwerkbw.de/gindumac-gebrauchtmaschinen-4-0/',
    },
    {
      image: 'is-grey_2.png',
      link: 'https://www.insightssuccess.in/gindumac-india-offering-world-class-products-services-cnc-machining-processes/',
    },
    {
      image: 'jg-grey_2.png',
      link: 'http://www.jeccomposites.com/knowledge/international-composites-news/kraussmaffei-takes-stake-second-hand-machinery-start',
    },
    {
      image: 'km-grey_2.png',
      link: 'https://www.kunststoff-magazin.de/spritzgiessen/finanzierungsmodelle-fuer-kunststoffmaschinen---spritzgiessmaschinen-mieten-statt-kaufen.htm',
    },
    {
      image: 'mm-grey_2.png',
      link: 'http://www.maschinenmarkt.vogel.de/industrie-40-pioniersystem-vernetzt-gebrauchtmaschinen-a-688590/',
    },
    {
      image: 'mw-grey_2.png',
      link: 'https://www.maschinewerkzeug.de/business-karriere/uebersicht/artikel/interview-mit-benedikt-ruf-und-janek-andre-5933009.html',
    },
    {
      image: 'mp-grey_2.png',
      link: 'https://mundoplast.com/kraussmaffei-compra-parte-de-gindumac/',
    },
    {
      image: 'nm-grey_2.png',
      link: 'https://ngin-mobility.com/artikel/gindumac-maschinen-an-und-verkauf/',
    },
    {
      image: 'pp-grey_2.png',
      link: 'http://www.pedeca.es/gindumac-en-la-feria-biehm-2018/',
    },
    {
      image: 'pv-grey_2.png',
      link: 'https://www.plastverarbeiter.de/83211/spritzgiessmaschine-mieten-statt-kaufen/',
    },
    {
      image: 'pl-grey_2.png',
      link: 'https://www.plastech.biz/en/news/KraussMaffei-Group-acquires-a-stake-in-Gindumac-12548',
    },
    {
      image: 'pli-grey_2.png',
      link: 'https://www.plasticsinsight.com/kraussmaffei-uses-gindumac-for-effective-operations-of-its-new-business-model-rent-it/',
    },
    {
      image: 'pt-grey_2.png',
      link: 'https://www.plasticstoday.com/injection-molding/kraussmaffei-acquires-stake-online-used-machinery-startup/32445798458972',
    },
    {
      image: 'pn-grey_2.png',
      link: 'https://www.plasticsnews.com/article/20180608/NEWS/306089997/kraussmaffei-invests-in-second-hand-machinery-firm-gindumac',
    },
    {
      image: 'pol-grey_2.png',
      link: 'https://www.polimerica.it/tag.asp?tag=Gindumac',
    },
    {
      image: 'rpn-grey_2.png',
      link: 'https://www.plasticsnews.com/article/20180608/NEWS/306089997/kraussmaffei-invests-in-second-hand-machinery-firm-gindumac',
    },
    {
      image: 'scope-grey_2.png',
      link: 'https://www.scope-online.de/dienstleistungen/gebrauchtmaschinenhandel.htm',
    },
    {
      image: 'wf-grey_2.png',
      link: 'https://www.werkzeug-formenbau.de/interview/initiative-eroeffnet-markttransparenz-und-vergleichbarkeit-in-der-branche-118.html',
    },
    {
      image: 'ww-grey_2.png',
      link: 'https://gruender.wiwo.de/gindumac-krauss-maffei-steigt-bei-gebrauchtmaschinenhaendler-ein/',
    },
  ];
  return (
    <div className='pt-12'>
      <Slider
        title={t('KNOWN_FROM_TITLE')}
        slidesToShow={3}
        autoplay
        slides={reviews.map((company, index) => {
          return (
            <div
              key={index}
              className='mx-auto flex  flex-col items-center justify-center'
              style={{
                paddingLeft: '1em',
                paddingRight: '1em',
              }}
            >
              <a
                href={company.link}
                className='h-24 w-48'
                target='_blank'
                rel='noreferrer'
              >
                <div className='quote-quote-logo flexCenter col s12'>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    alt=''
                    src={`images/home/known-from/${company.image}`}
                    className='mx-auto h-24 w-48'
                  />
                </div>
              </a>
            </div>
          );
        })}
      />
    </div>
  );
}

function Slider({
  title,
  slides,
  afterTitle,
  slidesToShow = 1,
  autoplay = false,
}: {
  title: string;
  slides: ReactNode;
  afterTitle?: ReactNode;
  slidesToShow?: number;
  autoplay?: boolean;
}) {
  return (
    <div className='mx-auto  max-w-[96vw] lg:max-w-3xl'>
      <div className='mx-auto flex flex-col items-center justify-center px-4 pb-4'>
        <h3 className='py-8 text-center uppercase'>{title}</h3>
        {afterTitle}
      </div>
      <SimpleSlider
        slidesToShow={slidesToShow}
        autoplay={autoplay}
        hasDots={false}
      >
        {slides}
      </SimpleSlider>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const queryClient = new QueryClient();
  try {
    await queryClient.fetchQuery(
      ['products'],
      () => getProducts({ page_size: 10, locale: locale ?? 'en-US' }),
      {
        retry: 8,
      }
    );
  } catch (error) {
    console.error(error);
  }
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};
