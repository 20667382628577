import useTranslation from 'next-translate/useTranslation';
import SimpleSlider from '@/components/section/CarouselList';
import { ReactNode } from 'react';
import Stars from '../ui/Molecules/Stars/Stars';

export default function GoogleReviews() {
  const { t } = useTranslation('products');
  const reviews = [
    {
      quote: t('products:GOOGLE_REVIEW_5'),
      company: 'Nerea Boleas',
    },
    {
      quote: t('products:GOOGLE_REVIEW_6'),
      company: 'Aleksander Tomas Vassiljev',
    },
    {
      quote: t('products:GOOGLE_REVIEW_7'),
      company: 'Iain Spink',
    },
    {
      quote: t('products:GOOGLE_REVIEW_8'),
      company: 'David Löwenbrand',
    },
  ];
  return (
    <Slider
      title={t('products:GOOGLE_REVIEW_TITLE')}
      afterTitle={
        <div className='flex flex-col items-center border-b-[1px] border-b-[#e0e0e0] pb-4'>
          <div className='flexCenter mx-auto'>
            <span style={{ fontSize: '2rem', fontWeight: 600 }}>4.9</span>
            <span style={{ fontSize: '1.2rem' }}>/5</span>
          </div>
          <Stars size='2rem' />
          {/* TODO: i18n */}
          <a
            href='https://www.google.com/maps/place/GINDUMAC+GmbH/@45.4602481,1.6109445,6z/data=!3m1!5s0x479612ad5fa94551:0xa48b61c0f94b7943!4m6!3m5!1s0x479612ad5c0fe2b3:0xb7c60fb5e4aa40e7!8m2!3d49.4323528!4d7.7537716!16s%2Fg%2F11swc9wcbn?entry=ttu'
            target='_blank'
            className='mt-4 text-brand-500'
            rel='noreferrer'
          >
            {process.env.NEXT_PUBLIC_GOOGLE_REVIEWS_NUMBER ?? 312} Reviews
          </a>
        </div>
      }
      slides={reviews.map((review, index) => {
        return (
          <div
            key={index}
            className='mx-auto flex  flex-col items-center justify-center'
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
            }}
          >
            <p className='mx-auto mt-4 max-w-lg text-center text-brand-500'>
              {review.quote}
            </p>
            <Stars size='1rem' />
            <p className='mr-4 mt-4 text-right text-brand-500 opacity-50'>
              - {review.company}
            </p>
          </div>
        );
      })}
    />
  );
}

function Slider({
  title,
  slides,
  afterTitle,
  slidesToShow = 1,
  autoplay = false,
}: {
  title: string;
  slides: ReactNode;
  afterTitle?: ReactNode;
  slidesToShow?: number;
  autoplay?: boolean;
}) {
  return (
    <div className='mx-auto  max-w-[96vw] lg:max-w-3xl'>
      <div className='mx-auto flex flex-col items-center justify-center px-4 pb-4'>
        <p className='h3 py-8 text-center uppercase'>{title}</p>
        {afterTitle}
      </div>
      <SimpleSlider
        slidesToShow={slidesToShow}
        autoplay={autoplay}
        hasDots={false}
      >
        {slides}
      </SimpleSlider>
    </div>
  );
}
