import React, { ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';
import NoSSR from '@/components/utils/NoSSR';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';

export default function SimpleSlider({
  children,
  slidesToShow = 3,
  autoplay = true,
  hasDots = true,
}: {
  children: ReactNode;
  slidesToShow?: number;
  autoplay?: boolean;
  hasDots?: boolean;
}) {
  const settings: Settings = {
    dots: hasDots,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: autoplay,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };
  return (
    <NoSSR>
      <div className='mx-4 max-w-full pb-12'>
        <div className='mx-12'>
          <Slider {...settings} arrows={true}>
            {children}
          </Slider>
          <style jsx global>{`
            .slick-next:before {
              color: gray;
            }
            .slick-prev:before {
              color: gray;
            }

            @media (min-width: 768px) {
              .slick-slider {
                padding-left: 2rem;
                padding-right: 2rem;
              }
            }

            .slick-slide > div {
              margin: 0 10px;
            }
            .slick-list {
              margin: 0 -10px;
            }
            .slick-prev,
            .slick-next {
              font-size: 15px !important;
            }
            .slick-prev:before,
            .slick-next:before {
              content: '' !important;
            }
            .slick-dots {
              position: relative;
            }
          `}</style>
        </div>
      </div>
    </NoSSR>
  );
}

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <button
      type='button'
      className={className}
      onClick={onClick}
      aria-label='previous new entries'
    >
      <MdArrowForwardIos className='text-2xl text-slate-500' />
    </button>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <button
      type='button'
      className={className}
      onClick={onClick}
      aria-label='previous new entries'
    >
      <MdArrowBackIos className='text-2xl text-slate-500' />
    </button>
  );
}
